import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'ocf-page-wizard',
    template: `<div class="container">
    <ol>
      <ng-content></ng-content>
    </ol>
  </div>`,
    styleUrls: ['./page-wizard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PageWizardComponent {
  @Input()
  step: number = 0;
}
