import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FILE_EXTENSION_ICON_MAP, KnownFileExtension } from './ocf-file-icon.mapping';

@Component({
    selector: 'ocf-file-icon',
    template: ` <mat-icon>{{ fileIconClass }}</mat-icon> `,
    styles: [
        `
      :host {
        line-height: 1em;
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OcfFileIconComponent {
  @Input() defaultIcon: KnownFileExtension = 'default';
  @Input() extension: (KnownFileExtension & string) | null = null;

  get fileIconClass(): string {
    const icon = FILE_EXTENSION_ICON_MAP[this.extension!];
    return icon || FILE_EXTENSION_ICON_MAP[this.defaultIcon];
  }
}
