import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ocf-wizard-button-row',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <cui-button *ngIf="!firstStep" (clicked)="back.emit()" i18n>Back</cui-button>
    <cui-button *ngIf="!lastStep" (clicked)="next.emit()" color="accent" disableRipple i18n disableRipple>Next</cui-button>
  `,
    styles: [
        `
      :host {
        display: grid;
        grid-auto-flow: column;
        justify-content: right;
        grid-gap: 8px;
      }
    `,
    ],
    standalone: false
})
export class OcfWizardButtonRowComponent {
  @Input() firstStep: boolean = false;
  @Input() lastStep: boolean = false;

  @Output() back = new EventEmitter<void>();
  @Output() next = new EventEmitter<void>();
}
