import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
    templateUrl: './loader-indicator.html',
    standalone: false
})
export class LoaderIndicatorComponent implements OnInit, OnDestroy {
  interval = 0.02;

  timeElapsed$!: Observable<number>;
  subscriber!: Subscription;
  progress$!: Observable<number>;

  checked = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: LoaderIndicatorData) {}

  ngOnInit(): void {
    this.initTimer(this.data.time);
  }

  initTimer(timePeriod: number): void {
    const intervalSeconds = timePeriod * this.interval;
    this.timeElapsed$ = interval(intervalSeconds * 1000);

    this.subscriber = this.timeElapsed$.pipe(filter(value => value * intervalSeconds === timePeriod)).subscribe(() => {
      this.data.check.next();
      this.resetTimer();
    });

    this.progress$ = this.timeElapsed$.pipe(map(count => ((count + 1) * 100 * intervalSeconds) / timePeriod));
  }

  resetTimer(): void {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }

    // divide total time by 2 before checking again
    this.initTimer(this.data.time / 2);
    this.checked = true;
  }

  ngOnDestroy(): void {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }
}

interface LoaderIndicatorData {
  time: number;
  check: Subject<void>;
}
