import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ocf-form-button-row',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <cui-button *ngIf="cancelText" [disabled]="disabled" (clicked)="cancel.emit()">{{ cancelText }}</cui-button>
    <cui-button *ngIf="saveText" [disabled]="disabled" (clicked)="save.emit()">{{ saveText }}</cui-button>
    <cui-button-cta *ngIf="submitText" type="submit" [disabled]="disabled || disableSubmit">{{ submitText }}</cui-button-cta>
  `,
    styles: [
        `
      :host {
        display: grid;
        grid-template: 'cancel . save submit' / auto 1fr auto auto;
        grid-column-gap: 16px;
      }

      cui-button:first-child {
        grid-area: cancel;
      }

      cui-button:not(:first-child) {
        grid-area: save;
      }

      cui-button-cta {
        grid-area: submit;
      }
    `,
    ],
    standalone: false
})
export class OcfFormButtonRowComponent {
  @Input() disabled: boolean = false;

  // ToDo: See if implementable
  @Input() cancelUrl: string[] = [];

  @Input() cancelText: string = '';
  @Input() cancelIcon = 'close';
  @Output() cancel = new EventEmitter<void>();

  @Input() saveText: string = '';
  @Input() saveIcon = 'save';
  @Output() save = new EventEmitter<void>();

  @Input() disableSubmit: boolean = false;
  @Input() submitText: string = '';
  @Input() submitIcon = 'send';
}
