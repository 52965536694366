import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'ocf-page-wizard-step',
    template: `<ng-content></ng-content>`,
    styleUrls: ['./page-wizard-step.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PageWizardStepComponent {
  @HostBinding('class.active')
  @Input()
  isActive: boolean = false;
}
