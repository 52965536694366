export type KnownFileExtension = 'zip' | 'rar' | 'xls' | 'xlsx' | 'doc' | 'docx' | 'txt' | 'default'

export const FILE_EXTENSION_ICON_MAP = {
  zip: 'archive',
  rar: 'archive',
  xls: 'insert_chart',
  xlsx: 'insert_chart',
  doc: 'text_format',
  docx: 'text_format',
  txt: 'text_format',
  default: 'insert_drive_file',
}
