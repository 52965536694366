import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ocf-form-readonly-button-row',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: ` <cui-button-cta type="button" (clicked)="closeClick.emit()">{{ closeText }}</cui-button-cta> `,
    styles: [
        `
      :host {
        display: flex;
        justify-content: flex-end;
      }
    `,
    ],
    standalone: false
})
export class OcfFormReadonlyButtonRowComponent {
  @Input() closeText: string = '';
  @Input() closeIcon = 'close';

  @Output() closeClick = new EventEmitter();
}
