<mat-dialog-content>
  <div>
    <div class="mat-loading">
      <mat-progress-spinner mode="determinate" [value]="(progress$ | async)!"></mat-progress-spinner>
    </div>
    <p i18n>Please wait while the calculation is being performed</p>
    <small i18n *ngIf="checked; else empty">Still calculating...</small>
    <ng-template #empty>
      <small>&nbsp;</small>
    </ng-template>
  </div>
</mat-dialog-content>
