import { AfterViewInit, Component, ContentChildren, Input, QueryList, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { OcfWizardStepComponent } from './ocf-wizard-step.component';

@Component({
    selector: 'ocf-wizard',
    template: `
    <mat-vertical-stepper #stepper>
      <mat-step
        *ngFor="let step of steps; first as isFirst; last as isLast; trackBy: trackBy"
        [stepControl]="step.formGroup"
        [completed]="step.isTouchedAndCompleted"
        [hasError]="step.isTouchedAndInvalid"
        [label]="step.title"
      >
        <ng-container [ngTemplateOutlet]="step.stepContent"></ng-container>
        <ocf-wizard-button-row
          [firstStep]="isFirst"
          [lastStep]="isLast"
          (back)="stepper.previous()"
          (next)="stepper.next()"
        ></ocf-wizard-button-row>
      </mat-step>
    </mat-vertical-stepper>
    <ng-content></ng-content>
  `,
    standalone: false
})
export class OcfWizardComponent implements AfterViewInit {
  /** The index (zero-based) of the step that should be expanded and active. */
  @Input() selectedStepIndex = 0;

  @ViewChild('stepper') stepper!: MatStepper;

  @ContentChildren(OcfWizardStepComponent)
  set stepsQuery(value: QueryList<OcfWizardStepComponent>) {
    this.steps = value.toArray();
  }

  steps: OcfWizardStepComponent[] = [];

  ngAfterViewInit(): void {
    if (this.selectedStepIndex) {
      this.stepper.selectedIndex = this.selectedStepIndex;
    }
  }

  trackBy(index: number, { title }: OcfWizardStepComponent): string {
    return title;
  }
}
